import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  routes: [
    { path: "/", name: "home", component: () => import("./pages/Login.vue") },
    {
      path: "/dashboard",
      name: "dashboard",
      component: () => import("./pages/Dashboard.vue"),
    },
    {
      path: "/categorii-drepturi",
      name: "categorii-drepturi",
      component: () =>
        import("./pages/categorii-drepturi/Categorii-drepturi_list.vue"),
    },
    {
      path: "/drepturi",
      name: "drepturi",
      component: () => import("./pages/drepturi/Drepturi_list.vue"),
    },
    {
      path: "/grupuri-utilizatori",
      name: "grupuri-utilizatori",
      component: () =>
        import("./pages/grupuri-utilizatori/Grupuri-utilizatori_list.vue"),
    },
    {
      path: "/utilizatori",
      name: "utilizatori",
      component: () => import("./pages/utilizatori/Utilizatori_list.vue"),
    },
    {
      path: "/judete",
      name: "judete",
      component: () => import("./pages/judete/Judete_list.vue"),
    },
    {
      path: "/orase",
      name: "orase",
      component: () => import("./pages/orase/Orase_list.vue"),
    },
    {
      path: "/abonamente",
      name: "abonamente",
      component: () => import("./pages/abonamente/Abonamente_list.vue"),
    },
    {
      path: "/facturi",
      name: "facturi",
      component: () => import("./pages/facturi/Facturi_list.vue"),
    },
    {
      path: "/documentespecialisti",
      name: "documentespecialisti",
      component: () =>
        import("./pages/documentespecialisti/Documentespecialisti_list.vue"),
    },
    {
      path: "/melodii",
      name: "melodii",
      component: () => import("./pages/melodii/Melodii_list.vue"),
    },
    {
      path: "/tipuricitatemotivationale",
      name: "tipuricitatemotivationale",
      component: () =>
        import(
          "./pages/tipuricitatemotivationale/Tipuricitatemotivationale_list.vue"
        ),
    },
    {
      path: "/citatemotivationale",
      name: "citatemotivationale",
      component: () =>
        import("./pages/citatemotivationale/Citatemotivationale_list.vue"),
    },
    {
      path: "/clienti",
      name: "clienti",
      component: () => import("./pages/clienti/Clienti_list.vue"),
    },
    {
      path: "/postari",
      name: "postari",
      component: () => import("./pages/postari/Postari_list.vue"),
    },
    {
      path: "/setari",
      name: "setari",
      component: () => import("./pages/setari/Setari_list.vue"),
    },
    {
      path: "/reclame",
      name: "reclame",
      component: () => import("./pages/reclame/Reclame_list.vue"),
    },
  ],
});
